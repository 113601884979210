import React, { useState } from 'react'
import { func, string } from 'prop-types'
import { useDispatch } from 'react-redux'
import { Button, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material'
import loaderLight from '@assets/images/loader-light.svg'
import { setCheckoutStep, setOrder } from '@redux/modules/checkout'
import { updatePayment } from '@services/checkout'
import PaymentSvg from '@shared/svgs/paymentSvgComp'
import { DialogDivider, LoadingSpinner } from './styles'

const AcimaModal = ({ closeModal, orderId }) => {
  const [loading, setLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const dispatch = useDispatch()

  const handleContinue = () => {
    setLoading(true)
    updatePayment({ orderId, paymentInfo: [{ paymentType: 'ACIMA', paymentProperties: {} }] })
      .then(finalOrder => {
        dispatch(setOrder({ ...finalOrder, selectedPaymentType: 'acima' }))
        closeModal()
        dispatch(setCheckoutStep('review'))
      })
      .catch(err => {
        console.error('Error updating payment type >> ', err)
        setIsError(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <DialogTitle id="payment-modal-title" component="div" sx={{ p: '12px 24px' }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h2" sx={{ fontSize: 19, fontWeight: 600, textTransform: 'capitalize' }}>
            Acima Leasing Payment
          </Typography>
          <PaymentSvg cordY="0" vpHeight={50} width={80} uniqueNameForId="paymentAcima" cards={['acima']} />
        </Stack>
      </DialogTitle>

      <DialogContent style={{ paddingTop: '8px' }}>
        {isError ? (
          <Typography> An error occurred while updating your payment type. Please try again later.</Typography>
        ) : (
          <>
            <Typography sx={{ textAlign: 'left', fontWeight: 600, pb: 2 }}>
              In the Review Order Step, click the Place Your Order button to open the Acima Leasing application, which
              must be completed to submit your order.
            </Typography>
            <Typography sx={{ textAlign: 'left' }}>
              Click CONTINUE to use Acima Leasing or CANCEL to select another payment type.
            </Typography>
          </>
        )}
      </DialogContent>

      <DialogDivider />

      <DialogActions sx={{ justifyContent: 'center', padding: '24px' }}>
        <Button fullWidth variant={isError ? 'contained' : 'outlined'} onClick={!loading ? closeModal : null}>
          {isError ? 'Close' : 'Cancel'}
        </Button>
        {!isError && (
          <Button
            disabled={isError}
            sx={{ height: '40px' }}
            fullWidth
            variant="contained"
            onClick={!loading ? handleContinue : null}
          >
            {!loading && 'Continue'}
            {loading && <LoadingSpinner alt="Assigning Acima as payment type" src={loaderLight} />}
          </Button>
        )}
      </DialogActions>
    </>
  )
}

AcimaModal.propTypes = {
  closeModal: func,
  orderId: string,
}

export default AcimaModal
