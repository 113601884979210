import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Card, CardContent, Typography, styled, Button } from '@mui/material'
import {
  getActualPaymentType,
  getPaymentCondensedAddressInfo,
  getPaymentDisplayInfo,
} from '@helpers/checkout/payment-section/payment-section'
import { ApplePayButton } from '@rtgdev/design-system'
import { useTest } from '@hooks/useTest'
import PaymentSvg from '@shared/svgs/paymentSvgComp'
import PriceAtom from '../../../../@rtg2022/components/atoms/ProductPrice/PriceAtom'

const StyledCard = styled(Card, { shouldForwardProp: prop => prop !== 'isMobile' })(({ isMobile }) => ({
  width: isMobile ? '100%' : '16.875rem',
  height: isMobile ? 'auto' : '12.125rem',
  maxWidth: isMobile && 400,
  boxShadow: 'none',
  display: 'flex',
  borderRadius: '10px',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'stretch',
}))

const StyledContent = styled(CardContent)({
  padding: '0.9375rem',
})

const PaymentSvgContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  '#apple-pay-button': {
    cursor: 'default',
    height: '44px !important',
    marginLeft: '-12px !important',
  },
  [theme.breakpoints.up('sm')]: {
    alignItems: 'baseline',
    paddingTop: '12px',
  },
}))

const PaymentSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  color: theme.palette.grey[600],
  [theme.breakpoints.up('sm')]: {
    whiteSpace: 'pre-line',
  },
}))

const FooterContainer = styled(CardContent)({
  padding: '8px',
  paddingBottom: '8px !important',
  backgroundColor: '#0053A014',
  height: '2.815rem',
  display: 'flex',
  alignItems: 'center',
})

const CardFooter = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
})

const StyledBillingText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: '0.625rem',
  fontWeight: 600,
}))

const StyledAddressText = styled(Typography)({
  fontSize: '0.75rem',
})

const RemoveButton = styled(Button)({
  textDecoration: 'underline',
  fontWeight: 600,
})

const AppliedContainer = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
})

const StyledAppliedText = styled('span')(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: '0.625rem',
  fontWeight: 600,
  marginRight: '4px',
}))

const StyledPriceAtom = styled(PriceAtom)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontWeight: 600,
    fontSize: '19px',
  },
}))

const PaymentSvgInfo = ({ type }) => {
  const paymentSvgProps = {
    ACIMA: { uniqueNameForId: 'paymentAcima', cards: ['acima'] },
    AFF: { uniqueNameForId: 'paymentAffirm', cards: ['affirm'] },
    bitpay: { uniqueNameForId: 'paymentBitpay', cards: ['bitpay'], width: 90 },
    BIT: { uniqueNameForId: 'paymentBitpay', cards: ['bitpay'], width: 90 },
    DBUY: { uniqueNameForId: 'paymentRtg', cards: ['rtg'] },
    FIN: { uniqueNameForId: 'paymentRtg2', cards: ['rtg'] },
    GIFT: { uniqueNameForId: 'paymentGift', cards: ['gift'], renderGiftCard: true },
    '001': { uniqueNameForId: 'paymentVisa', cards: ['visa'] },
    '002': { uniqueNameForId: 'paymentMc', cards: ['mastercard'] },
    '003': { uniqueNameForId: 'paymentAmex', cards: ['amex'] },
    '004': { uniqueNameForId: 'paymentDiscover', cards: ['discover'] },
    '042': { uniqueNameForId: 'paymentMaestro', cards: ['maestro'] },
    PALV2: { uniqueNameForId: 'paymentPaypal', cards: ['paypal'] },
    GEN: { uniqueNameForId: 'paymentConcora', cards: ['genesis'] },
    WFG: { uniqueNameForId: 'paymentWellsFargo', cards: ['wfg'] },
  }

  if (type === 'KLN') {
    return (
      <img
        src="https://x.klarnacdn.net/payment-method/assets/badges/generic/klarna.svg"
        width="60px"
        alt="Klarna payment option"
        style={{ backgroundColor: '#ffb3c7', borderRadius: '5px', maxHeight: '55px' }}
      />
    )
  }

  if (type === 'APP') {
    return <ApplePayButton buttonStyle="white" type="plain" />
  }

  return <PaymentSvg cordY="0" vpHeight={50} width={60} {...(paymentSvgProps[type] ?? {})} />
}

PaymentSvgInfo.propTypes = {
  type: PropTypes.string,
}

const AppliedPaymentCard = ({ isMobile, payment, order, handlePaymentRemoval, showRemove = false }) => {
  const { generateTestId } = useTest()
  const actualPaymentType = getActualPaymentType(payment)
  const isCreditCard = ['CYBERV3', 'CYBERV4'].includes(actualPaymentType)
  const cardType = isCreditCard ? payment?.paymentProperties?.card?.cardType : ''
  const showBilling = !['ACIMA', 'bitpay', 'BIT', 'GIFT'].includes(actualPaymentType)
  const addressInfo = showBilling ? getPaymentCondensedAddressInfo(order, actualPaymentType, payment) : {}
  const { displayName, displayAddr1, displayAddr2 } = addressInfo
  const { paymentName, subTitle } = getPaymentDisplayInfo(actualPaymentType, payment)

  return (
    <StyledCard variant="outlined" isMobile={isMobile}>
      <StyledContent sx={{ borderBottom: showBilling ? '1px solid lightgray' : '', padding: '0.6375rem' }}>
        <Grid container spacing={1}>
          <PaymentSvgContainer item xs={actualPaymentType === 'APP' ? 4 : 3}>
            <PaymentSvgInfo type={isCreditCard ? cardType : actualPaymentType} />
          </PaymentSvgContainer>
          <Grid item xs={actualPaymentType === 'APP' ? 8 : 9}>
            <Typography>{paymentName}</Typography>
            <PaymentSubtitle display="block">{subTitle}</PaymentSubtitle>
          </Grid>
        </Grid>
      </StyledContent>
      <StyledContent>
        {showBilling ? (
          <Grid container spacing={3}>
            <Grid item xs={3} sx={{ pt: '15px !important' }}>
              <StyledBillingText textTransform="uppercase">Billing</StyledBillingText>
            </Grid>
            <Grid item xs={9} sx={{ pt: '15px !important', pl: '15px !important' }}>
              {' '}
              <StyledAddressText textTransform="capitalize">
                {displayName} <br />
                {displayAddr1} <br />
                {displayAddr2}
              </StyledAddressText>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </StyledContent>
      <FooterContainer>
        <CardFooter>
          <div>
            {showRemove && (
              <RemoveButton data-testid={generateTestId('payment', 'remove-btn')} onClick={handlePaymentRemoval}>
                Remove
              </RemoveButton>
            )}
          </div>
          <AppliedContainer textTransform="uppercase" fontWeight={500}>
            <StyledAppliedText>Applied</StyledAppliedText>{' '}
            <StyledPriceAtom
              dataTestId={generateTestId('payment', 'applied-amount')}
              price={payment.authorizedAmount}
            />
          </AppliedContainer>
        </CardFooter>
      </FooterContainer>
    </StyledCard>
  )
}

AppliedPaymentCard.propTypes = {
  isMobile: PropTypes.bool,
  payment: PropTypes.object,
  order: PropTypes.object,
  handlePaymentRemoval: PropTypes.func,
  showRemove: PropTypes.bool,
}

export default AppliedPaymentCard
