import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { closeSuggestionModal, acceptAddressSuggestion, declineAddressSuggestion } from '@helpers/checkout/global'
import './address-suggestion-modal.sass'

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#___gatsby')

const AddressSuggestionModal = ({
  address,
  closeModal,
  formattedSuggestion,
  modalOpen,
  setBillingState,
  suggestion,
}) => {
  const orderAddress = [
    `${address.address1} ${address.address2 ? `${address.address2} ` : ''}`,
    `${address.city} ${address.state.toUpperCase()} ${address.zip}`,
  ]

  const suggestedAddress = suggestion && suggestion.split(',')
  const handleClose = closeModal ?? closeSuggestionModal

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={handleClose}
      contentLabel="Address Suggestion Modal"
      className="address-suggestion-modal"
      overlayClassName="modal-overlay"
    >
      <div className="modal-content">
        <span className="modal-title" role="heading" aria-level="1">
          We are having trouble verifying the address as it was entered.
        </span>
        <div className="grid-x">
          {suggestedAddress && (
            <div className="small-12 medium-6">
              <span
                className="modal-title"
                role="heading"
                aria-level="2"
                // eslint-disable-next-line
                tabindex={0}
                aria-label={`Suggested Address: ${suggestedAddress[0]} ${suggestedAddress[1]}`}
              >
                Suggested address:
              </span>
              <span className="address">{suggestedAddress[0]}</span>
              <span className="address">{suggestedAddress[1]}</span>
              <button
                type="button"
                className="accept-btn"
                tabIndex="0"
                value="accept address suggestion"
                aria-label="accept address suggestion and continue to delivery section"
                onClick={() => acceptAddressSuggestion(suggestion, setBillingState, formattedSuggestion)}
              >
                ACCEPT ADDRESS SUGGESTION
              </button>
            </div>
          )}
          <div className="small-12 medium-6">
            <span
              className="modal-title"
              role="heading"
              aria-level="2"
              // eslint-disable-next-line
              tabindex={0}
              aria-label={`Address as entered: ${orderAddress[0]} ${orderAddress[1]}`}
            >
              Address as entered:
            </span>
            <span style={{ textTransform: 'capitalize' }} className="address">
              {orderAddress[0]}
            </span>
            <span style={{ textTransform: 'capitalize' }} className="address">
              {orderAddress[1]}
            </span>
            <button
              type="button"
              className="keep-btn"
              tabIndex="0"
              value="keep address as entered"
              aria-label="keep address as entered and continue to delivery section"
              onClick={() => declineAddressSuggestion(setBillingState)}
            >
              KEEP ADDRESS AS ENTERED
            </button>
          </div>
        </div>
        <button
          type="button"
          className="go-back"
          tabIndex="0"
          value="Close"
          aria-label="Close and return to shipping section"
          onClick={handleClose}
        >
          {'< GO BACK AND EDIT THE ADDRESS PROVIDED'}
        </button>
        <button
          type="button"
          className="close-modal"
          tabIndex="0"
          value="Close"
          aria-label="Close and return to shipping section"
          onClick={handleClose}
        >
          <img
            className="icon close"
            alt="close icon"
            src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23FFFFFF' d='M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z' /%3E%3C/svg%3E"
          />
        </button>
      </div>
    </Modal>
  )
}

AddressSuggestionModal.propTypes = {
  address: PropTypes.object,
  closeModal: PropTypes.any,
  formattedSuggestion: PropTypes.object,
  modalOpen: PropTypes.bool,
  setBillingState: PropTypes.any,
  suggestion: PropTypes.string,
}

export default AddressSuggestionModal
