import React from 'react'
import { Button, Stack } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { ButtonLink } from '@templates/strapi-cms/content-types/Link'

const ReturnToCart = () => (
  <Stack direction="row" alignItems="center" sx={{ display: { xs: 'flex', md: 'none' } }}>
    <Button
      component={ButtonLink}
      data-testid="cartButton"
      data={{ InternalUrl: '/cart', title: 'Return to cart' }}
      sx={{ fontSize: '14px', padding: '12px 16px 8px 8px' }}
      trackingData={{ category: 'header', action: 'return to cart click', label: 'return to cart' }}
    >
      <ChevronLeftIcon sx={{ fontSize: '1.5rem' }} />
      Return to Cart
    </Button>
  </Stack>
)

export default ReturnToCart
