import React, { useEffect, useState } from 'react'
import { bool, func, object, string } from 'prop-types'
import { useDispatch } from 'react-redux'
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormGroup,
  FormControlLabel,
  Stack,
  Typography,
  styled,
} from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import loaderDark from '@assets/images/loader-dark.svg'
import loaderLight from '@assets/images/loader-light.svg'
import { setOrder } from '@redux/modules/checkout'
import { getWellsFargoPromoTerms, updatePayment } from '@services/checkout'
import RTGLink from '@shared/link'
import { LoadingSpinner } from '../styles'
import WellsFargoModalTitle from './wells-fargo-modal-title'

const CheckBoxLabel = styled(Typography)({
  fontSize: '12px',
  paddingTop: '8px',
  textAlign: 'left',
})

const CheckBoxWarning = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  display: 'block',
  fontSize: '14px',
  fontWeight: 600,
  marginTop: '16px',
}))

const WellsFargoConsent = ({
  downPayment,
  loading,
  handleBack,
  handleCancel,
  payment,
  promoBody,
  setLoading,
  wfPaymentBody,
}) => {
  const [isError, setIsError] = useState(false)
  const [promoTerms, setPromoTerms] = useState({ disclosure: '' })
  const [isCheckedConsent, setIsCheckedConsent] = useState(false)
  const [isCheckedSignature, setIsCheckedSignature] = useState(false)
  const [isWarned, setIsWarned] = useState(false)
  const [invalidFields, setInvalidFields] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    const getTerms = async () => {
      try {
        const terms = await getWellsFargoPromoTerms(promoBody)
        setPromoTerms({
          ...terms,
          disclosure: `${terms.disclosure} ${
            // eslint-disable-next-line prefer-template
            downPayment ? 'Down payment required at the time of purchase: ' + downPayment : 'No down payment required'
          }.`,
        })
      } catch (error) {
        setIsError(true)
      }
      setLoading(false)
    }

    getTerms()
  }, []) // eslint-disable-line

  const canContinue = !isWarned || (isCheckedConsent && isCheckedSignature)

  const handleCheckBox = (checked, type) => {
    setInvalidFields(current => current.filter(f => f !== type))
    if (type === 'consent') {
      setIsCheckedConsent(checked)
    } else {
      setIsCheckedSignature(checked)
    }
  }

  const handleSubmit = () => {
    if (!isCheckedConsent || !isCheckedSignature) {
      const newInvalid = []
      if (!isCheckedConsent) newInvalid.push('consent')
      if (!isCheckedSignature) newInvalid.push('signature')
      setInvalidFields(newInvalid)
      return setIsWarned(true)
    }

    setLoading(true)
    return updatePayment(wfPaymentBody)
      .then(updatedOrder => {
        dispatch(setOrder({ ...updatedOrder, selectedPaymentType: 'wells fargo' }))
        handleCancel()
      })
      .catch(err => {
        console.error('Error updating payment type >> ', err)
        setIsError(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <WellsFargoModalTitle handleBack={handleBack} />

      <DialogContent style={{ padding: '8px 24px 0' }}>
        {isError ? (
          <Typography> An error occurred while updating your payment type. Please try again later.</Typography>
        ) : (
          <>
            <Typography sx={{ textAlign: 'left', fontWeight: 600 }}>Consent To Electronic Delivery</Typography>
            <Typography sx={{ textAlign: 'left', fontWeight: 400, fontSize: '12px', pb: 1 }}>
              In order to process your transaction, we need your consent to deliver disclosures for this transaction to
              you electronically. Please review the terms and conditions of our ESIGN Consent and indicate your
              willingness to receive Special Terms and APR Information and other disclosures that may be required for
              this transaction electronically by checking the box below. If you do not consent to receive these
              documents electronically, you will not be able to process this transaction online.
            </Typography>
            <RTGLink
              data={{
                target: '_blank',
                url: 'https://misc.rtg-prod.com/WFG_Electronic_Disclosure_Consent_122023.pdf',
                action: 'click',
                label: 'view esign consent',
              }}
            >
              <span style={{ borderBottom: '1px solid #0053A0', color: '#0053A0', fontSize: '12px' }}>
                View ESIGN Consent (PDF)
              </span>
              <span className="hide508">(opens in new window)</span>
            </RTGLink>
            <FormGroup>
              <FormControlLabel
                sx={{ alignItems: 'flex-start', mr: 0 }}
                required
                control={<Checkbox size="small" onChange={e => handleCheckBox(e.target.checked, 'consent')} />}
                label={
                  <CheckBoxLabel sx={invalidFields.includes('consent') ? { fontWeight: 600 } : null}>
                    I consent to the terms and conditions of ESIGN Consent (PDF) to receive documents electronically. I
                    am able to receive and view email and PDF files.
                  </CheckBoxLabel>
                }
              />
            </FormGroup>

            <Stack direction="row" spacing={1} mt={2} mb={2}>
              <Typography sx={{ fontWeight: 600 }}>Total Amount Financed:</Typography>
              <Typography>{payment}</Typography>
            </Stack>

            <Stack mb={2}>
              <Typography sx={{ fontWeight: 600 }}>Special Terms And APR Information</Typography>
              {!promoTerms.disclosure ? (
                <div style={{ height: '16px', paddingLeft: '8px' }}>
                  <LoadingSpinner alt="Submitting Wells Fargo e-signature" src={loaderDark} />
                </div>
              ) : (
                <>
                  <Typography sx={{ fontWeight: 500, fontSize: '12px' }}>{promoTerms.disclosure}</Typography>
                  {/* <Typography sx={{ fontWeight: 400, fontSize: '12px' }}>{termsContent}</Typography> */}
                </>
              )}
            </Stack>

            <Typography sx={{ fontWeight: 400, fontSize: '12px', pb: 1.5 }}>
              {`An electronic copy of this invoice will be emailed to you at ${promoBody.emailAddress}.`}
            </Typography>

            <Typography sx={{ fontWeight: 600 }}>Electronic Signature</Typography>
            <FormGroup>
              <FormControlLabel
                sx={{ alignItems: 'flex-start', mr: 0 }}
                required
                control={<Checkbox size="small" onChange={e => handleCheckBox(e.target.checked, 'signature')} />}
                label={
                  <CheckBoxLabel component="div" sx={invalidFields.includes('signature') ? { fontWeight: 600 } : null}>
                    By checking this box, I acknowledge that this is my electronic signature and that this transaction
                    is under my Credit Card Agreement with Wells Fargo Bank, N.A. I acknowledge receipt of the following
                    documents at the time I opened my account.
                    <ul style={{ listStyle: 'unset', marginLeft: '20px' }}>
                      <li>Credit Card Agreement</li>
                      <li>Important Terms of Your Credit Card Account</li>
                      <li>Wells Fargo Retail Services Privacy Notice</li>
                    </ul>
                  </CheckBoxLabel>
                }
              />
            </FormGroup>
            {invalidFields.length > 0 && (
              <Stack direction="row" spacing={1} alignItems="center">
                <ErrorOutlineIcon color="error" />
                <CheckBoxWarning>Both check boxes must be checked to continue.</CheckBoxWarning>
              </Stack>
            )}
          </>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'center', padding: '24px' }}>
        <Button fullWidth variant={isError ? 'contained' : 'outlined'} onClick={!loading ? handleCancel : null}>
          {isError ? 'Close' : 'Cancel'}
        </Button>
        {!isError && (
          <Button
            disabled={isError || !canContinue}
            sx={{ height: '40px' }}
            fullWidth
            variant="contained"
            onClick={!loading ? handleSubmit : null}
          >
            {!loading && 'Submit'}
            {loading && <LoadingSpinner alt="Submitting Wells Fargo e-signature" src={loaderLight} />}
          </Button>
        )}
      </DialogActions>
    </>
  )
}

WellsFargoConsent.propTypes = {
  downPayment: string,
  loading: bool,
  handleBack: func,
  handleCancel: func,
  payment: string,
  promoBody: object,
  setLoading: func,
  wfPaymentBody: object,
}

export default WellsFargoConsent
