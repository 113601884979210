import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { any, object, objectOf, arrayOf, string } from 'prop-types'
import { useTheme } from '@mui/material/styles'
import { styled, Button, Grid, Stack, Typography, useMediaQuery } from '@mui/material'
import _omit from 'lodash/omit'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import { format } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'
import { updateDelivery, checkSplitElig, updatePayment } from '@services/checkout'
import { setCheckoutStep } from '@helpers/checkout/global'
import { getDeliverySpecificBody, getSplitShipmentData } from '@helpers/checkout/delivery-section'
import { formatAvailableInDate } from '@helpers/date'
import { sentryLogger, levels, setExtra, logSentry } from '@helpers/sentry-logger'
import { currencyFormatUS } from '@helpers/string-helper'
import { updateDeliveryAnalytics } from '@helpers/google-tag-manager'
import {
  setOrder,
  setSplitDeliveryDates,
  setDeliveryMode,
  setShowExpressModal,
  setDeliverySectionVisited,
} from '@redux/modules/checkout'
import { getLocation } from '@services/location'
import Spinner from '@components/generic/loader'
import { store } from '@redux/store'
import { getStore, calculateDistance } from '@helpers/store-locator'
import { removeKlarnaSession } from '@helpers/checkout/payment-section/payment-section'
import { useOrderSummary } from '@components/cart/useOrderSummary'
import ExpressModal from './express-modal/express-modal'
import DeliveryOptions from './delivery-options'
import DeliveryInstructions from './delivery-instructions'
import NonRTGDeliveryTile from './delivery-section-non-rtg-option'
import SingleDelivery from './delivery-section-single-option'
import SplitDelivery from './delivery-section-split-option'
import SplitDeliveryOptionPicker from './split-delivery-option-picker'

const DeliveryTitle = styled(Typography, { shouldForwardProp: prop => prop !== 'isMobile' })(({ isMobile }) => ({
  fontSize: isMobile ? '18px' : '19px',
  fontWeight: isMobile ? 600 : 500,
}))

const ShowInstructionsButton = styled(Button)`
  font-size: 16px;
  text-decoration: underline;
  text-transform: none;
`

const SpinnerContainer = styled('div')`
  display: flex;
  justify-content: center;
`

const removeAllPaymentMethods = async order => {
  if (!order || !order.paymentInfo) return null

  const data = await updatePayment({ orderId: order.orderId, paymentInfo: [] })
  store.dispatch(setOrder(data))
  removeKlarnaSession()
  return null
}

const getFirstAvailablePickupDate = dates => {
  // First available pickup date must be after today or updateDelivery API call will return error
  if (!dates?.length) return ''
  const todayString = format(new Date(), 'yyyy-MM-dd')
  return dates[0] === todayString ? dates[1] ?? '' : dates[0]
}

const getDefaultDates = order => {
  const { deliveryDate, pickupCalendar = [] } = order
  const hasExpressLineItems = _get(order, 'lineItems.0.deliveryType') === 'E'
  const doorway = _get(order, 'deliveryCalendar.0', _get(order, 'deliveryDate', ''))
  const delivery = hasExpressLineItems
    ? _get(order, 'expressCalendar.0', _get(order, 'deliveryCalendar.0', ''))
    : doorway
  const express = _get(order, 'expressCalendar.0', _get(order, 'deliveryDate', ''))
  const pickup = getFirstAvailablePickupDate(pickupCalendar)

  return { delivery, doorway, express, pickup: (pickup || deliveryDate) ?? '' }
}

const getDefaultSplitDates = (deliveryDate, splitCalendar = []) => {
  const finalShipmentCalendar = splitCalendar[splitCalendar.length - 1].deliveryCalendar
  const { deliveryDates = [], expressDeliveryDates = [], pickupDates = [] } = finalShipmentCalendar
  return getDefaultDates({
    deliveryDate,
    deliveryCalendar: deliveryDates,
    expressCalendar: expressDeliveryDates,
    pickupCalendar: pickupDates,
  })
}

const getSplitShipmentDates = (deliveryDate, splitCalendar = []) => {
  const newCal = splitCalendar.map(({ deliveryCalendar }) =>
    getDefaultDates({
      deliveryDate,
      deliveryCalendar: deliveryCalendar?.deliveryDates ?? [],
      expressCalendar: deliveryCalendar?.expressDeliveryDates ?? [],
      pickupCalendar: deliveryCalendar?.pickupDates ?? [],
    }),
  )
  return {
    delivery: newCal.map(d => d.delivery),
    doorway: newCal.map(d => d.doorway),
    express: newCal.map(d => d.express),
    pickup: newCal.map(d => d.pickup),
  }
}

const calculateStoreDistance = async order => {
  const data = await getLocation(order?.shippingAddress.zip)
    .then(res => res)
    .catch(() => null)
  const pickupStore = getStore(order)
  return data
    ? Math.ceil(
        calculateDistance(data?.response.lat, data?.response.long, pickupStore?.latitude, pickupStore?.longitude),
      )
    : false
}

const DeliverySectionForm = ({ cart = {}, defaultDeliveryInterval, nonRTGDeliveries, order, rtgDeliveryItems }) => {
  const [isLoading, setIsLoading] = useState(true)
  const showExpressModal = useSelector(state => state.checkout.showExpressModal)
  const [canChooseSplitDelivery, setCanChooseSplitDelivery] = useState(false)
  const deliveryMode = useSelector(state => state.checkout.deliveryMode)
  const step = useSelector(state => state.checkout.checkoutStep)
  const [distanceToStore, setDistanceToStore] = useState(null)
  const deliverySectionVisited = useSelector(state => state.checkout.deliverySectionVisited)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const dispatch = useDispatch()
  const { includesExpressFee } = useOrderSummary()

  const isSplitDelivery = !!(order?.splitDeliveryDates?.length ?? false)

  const hasStoreCartItems = order?.lineItems?.filter(lineItem => lineItem?.isStoreSku)?.length > 0 ?? false
  const hasDeliveryItems = rtgDeliveryItems.length > 0 && (cart?.cartItems?.length > 0 || hasStoreCartItems)
  const hasVendorDeliveryItems = nonRTGDeliveries?.length > 0
  const isVendorDeliveryOnly = !hasDeliveryItems && hasVendorDeliveryItems
  const hasDeliveryMode = !!deliveryMode || isVendorDeliveryOnly
  const isDoorwayDelivery = !!order?.doorwayDelivery
  const stringedCart = JSON.stringify(order.lineItems)
  const [showInstructions, setShowInstructions] = useState(!order.isPickup && !!order.additionalDirections)

  const getDeliveryCharge = useCallback(() => {
    if (!order.isPickup && isDoorwayDelivery) return `${currencyFormatUS(order?.doorwayDeliveryCharge ?? 0)}`
    if (!order.isPickup && !isDoorwayDelivery) return `${currencyFormatUS(order?.totalDeliveryCharge ?? 0)}`
    if (order.isPickup) return 'Free'
    return ''
  }, [isDoorwayDelivery, order.doorwayDeliveryCharge, order.isPickup, order.totalDeliveryCharge])

  const [deliveryChargesValue, setDeliveryChargesValue] = useState(getDeliveryCharge())

  const defaultNonSplitDates = getDefaultDates(order)
  const defaultSplitDates = order.splitCalendar ? getDefaultSplitDates(order.deliveryDate, order.splitCalendar) : {}
  const splitShipmentDates = order.splitCalendar ? getSplitShipmentDates(order.deliveryDate, order.splitCalendar) : {}
  const splitCalendarLength = order?.splitCalendar?.length ?? 0

  useEffect(() => {
    const previousDeliveryChargesValue = deliveryChargesValue
    const thisDeliveryChargesValue = getDeliveryCharge()
    if (previousDeliveryChargesValue !== thisDeliveryChargesValue) {
      setDeliveryChargesValue(thisDeliveryChargesValue)
      removeAllPaymentMethods(order)
    }
  }, [
    order.isPickup,
    order.doorwayDelivery,
    order.doorwayDeliveryCharge,
    order.totalDeliveryCharge,
    deliveryChargesValue,
    getDeliveryCharge,
    order,
  ])

  useEffect(() => {
    async function fetchSplitCheckElig(ordId, zip) {
      const addressAllowsSplitting = await checkSplitElig(ordId, zip)
      const canSplit = addressAllowsSplitting && splitCalendarLength > 0
      setCanChooseSplitDelivery(canSplit)
      if (!canSplit && order && !deliverySectionVisited) {
        toggleSplit(false)
      } else {
        setIsLoading(false)
      }
    }

    try {
      if (order.orderId && order?.shippingAddress?.zip) {
        fetchSplitCheckElig(order.orderId, order?.shippingAddress?.zip)
      }
    } catch (error) {
      sentryLogger({
        configureScope: {
          type: setExtra,
          message: `cart - fetching split check eligibility: ${error}`,
          level: levels.error,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringedCart, order.orderId, deliverySectionVisited])

  const toggleSplit = useCallback(
    isSplit => {
      if (!isLoading) setIsLoading(true)
      const newOrder = isSplit
        ? { ...order, deliveryDate: defaultSplitDates[deliveryMode], splitDelivery: splitShipmentDates[deliveryMode] }
        : { ..._omit(order, ['splitDelivery', 'splitDeliveryDates']), deliveryDate: defaultNonSplitDates[deliveryMode] }

      const newDeliveryInfo = getDeliverySpecificBody(newOrder, deliveryMode)

      if (!newDeliveryInfo.deliveryDate) newDeliveryInfo.deliveryDate = order.deliveryDate // fixing missing deliveryDate for store cart orders

      // Update if existing order date hasn't already been changed
      if (newDeliveryInfo.deliveryDate === order.deliveryDate)
        updateDelivery(newDeliveryInfo)
          .then(data => {
            dispatch(setOrder(data))
            if (!isSplit) dispatch(setSplitDeliveryDates({}))
            updateDeliveryAnalytics(data)
          })
          .catch(() => {
            store.dispatch(setDeliveryMode())
            logSentry(order, 'Update Delivery Failure')
          })
          .finally(() => {
            setIsLoading(false)
          })

      setIsLoading(false)
    },
    [defaultNonSplitDates, defaultSplitDates, deliveryMode, dispatch, isLoading, order, splitShipmentDates],
  )

  useEffect(() => {
    calculateStoreDistance(order).then(result => setDistanceToStore(result))
  }, [order])

  const deliveryCount = useMemo(() => {
    const count = nonRTGDeliveries?.length ?? 0
    if (isVendorDeliveryOnly) return count

    return isSplitDelivery ? count + splitCalendarLength : count + 1
  }, [isSplitDelivery, isVendorDeliveryOnly, nonRTGDeliveries, splitCalendarLength])

  if (isLoading) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    )
  }
  const computeDeliveryIndex = index => {
    if (isVendorDeliveryOnly) return index + 1
    return isSplitDelivery ? splitCalendarLength + index + 1 : 2 + index
  }

  let earliestDeliveryDates
  const splitCalendars = splitCalendarLength ? order.splitCalendar[0]?.deliveryCalendar ?? {} : {}
  const hasSplitCalendars = Object.keys(splitCalendars).length > 0
  if (canChooseSplitDelivery && hasSplitCalendars) {
    earliestDeliveryDates = {
      delivery: formatAvailableInDate(
        splitCalendars?.expressDeliveryDate ||
          (splitCalendars?.deliveryDates?.length ? splitCalendars?.deliveryDates[0] : null),
      ),
      doorway: formatAvailableInDate(
        splitCalendars?.expressDeliveryDate ||
          (splitCalendars?.deliveryDates?.length ? splitCalendars?.deliveryDates[0] : null),
      ),
      pickup: formatAvailableInDate(splitCalendars?.pickupDates?.length ? splitCalendars?.pickupDates[0] : null),
    }
  } else {
    earliestDeliveryDates = {
      delivery: formatAvailableInDate(defaultNonSplitDates?.express ?? defaultNonSplitDates?.delivery),
      doorway: formatAvailableInDate(defaultNonSplitDates?.express ?? defaultNonSplitDates?.doorway),
      pickup: formatAvailableInDate(defaultNonSplitDates?.pickup),
    }
  }

  const showSplitDeliveryOptionPicker = hasDeliveryMode && canChooseSplitDelivery && !!order?.splitCalendar
  const shipmentsData = !isSplitDelivery ? [] : getSplitShipmentData(cart.cartItems, order)

  const isSpaceOrEnterKey = key => [' ', 'Enter', 'NumpadEnter', 'Spacebar'].includes(key)

  const closeExpressModal = async event => {
    if (event?.type === 'keydown' && !isSpaceOrEnterKey(event?.key)) return null
    dispatch(setShowExpressModal(false))

    const invalidFields = await setCheckoutStep(event, step, 'payment')
    if (invalidFields?.length === 0) {
      return dispatch(setDeliverySectionVisited(step === 'delivery'))
    }
    return null
  }

  return (
    <>
      {(hasDeliveryItems || isVendorDeliveryOnly) && (
        <Stack>
          {!isVendorDeliveryOnly && (
            <Stack mb="20px">
              <DeliveryTitle isMobile={isMobile} mb={{ xs: '14px', sm: '24px' }} pl={{ xs: 0, sm: '6px' }}>
                Delivery Type:
              </DeliveryTitle>
              <DeliveryOptions
                defaultNonSplitDates={defaultNonSplitDates}
                defaultSplitDates={defaultSplitDates}
                deliveryCount={deliveryCount}
                distanceToStore={distanceToStore}
                earliestDeliveryDates={earliestDeliveryDates}
                hasDeliveryMode={hasDeliveryMode}
                includesExpressFee={includesExpressFee}
                isMobile={isMobile}
                isSplitDelivery={isSplitDelivery}
                order={order}
                showSplitDeliveryOptionPicker={showSplitDeliveryOptionPicker}
                splitShipmentDates={splitShipmentDates}
                shipmentsData={shipmentsData}
                toggleSplit={toggleSplit}
              />
            </Stack>
          )}

          {showExpressModal && (
            <ExpressModal
              open={showExpressModal}
              deliveryMode={deliveryMode}
              earliestDeliveryDates={earliestDeliveryDates}
              onClose={event => closeExpressModal(event)}
              isSplitDelivery={isSplitDelivery}
              cart={cart}
              order={order}
            />
          )}

          {showSplitDeliveryOptionPicker && !isMobile && (
            <SplitDeliveryOptionPicker
              isPickup={deliveryMode === 'pickup'}
              isSplitDelivery={isSplitDelivery}
              toggleSplit={toggleSplit}
            />
          )}

          {hasDeliveryMode && (
            <Stack>
              {!isVendorDeliveryOnly && (
                <DeliveryTitle isMobile={isMobile} mb="14px" pl={{ xs: 0, sm: '6px' }}>
                  {isMobile ? 'Delivery Details' : 'Delivery Date:'}
                </DeliveryTitle>
              )}
              <Grid container>
                {!isVendorDeliveryOnly && (
                  <>
                    {isSplitDelivery ? (
                      <SplitDelivery
                        deliveryCount={deliveryCount}
                        includesExpressFee={includesExpressFee}
                        isDeliveryEditable
                        isMobile={isMobile}
                        order={order}
                        shipmentsData={shipmentsData}
                      />
                    ) : (
                      <SingleDelivery
                        cartItems={cart?.cartItems ?? []}
                        defaultDeliveryInterval={defaultDeliveryInterval}
                        deliveryCount={deliveryCount}
                        includesExpressFee={includesExpressFee}
                        order={order}
                        rtgDeliveryItems={rtgDeliveryItems}
                        isDeliveryEditable
                        isMobile={isMobile}
                      />
                    )}
                  </>
                )}

                {nonRTGDeliveries.map(({ delivery, type }, index) => (
                  <NonRTGDeliveryTile
                    key={`${type}_items`}
                    deliveryItems={delivery}
                    cartItems={cart.cartItems ?? []}
                    type={type}
                    deliveryCount={deliveryCount}
                    deliveryDate={order.deliveryDate}
                    deliveryIndex={computeDeliveryIndex(index)}
                  />
                ))}

                {!isMobile && !order.isPickup && !isVendorDeliveryOnly && (
                  <Grid item xs={12} mb={2}>
                    {showInstructions ? (
                      <>
                        <DeliveryTitle isMobile={isMobile} mb="5px" pl={{ xs: 0, sm: '6px' }}>
                          Delivery Instructions:
                        </DeliveryTitle>
                        <DeliveryInstructions additionalDirections={order.additionalDirections} />
                      </>
                    ) : (
                      <ShowInstructionsButton
                        onClick={() => setShowInstructions(true)}
                        size="medium"
                        sx={{ pl: { xs: 0, sm: '6px' } }}
                      >
                        + Add Delivery Instructions
                      </ShowInstructionsButton>
                    )}
                  </Grid>
                )}
              </Grid>
            </Stack>
          )}
        </Stack>
      )}
    </>
  )
}

DeliverySectionForm.propTypes = {
  cart: objectOf(any),
  defaultDeliveryInterval: string,
  nonRTGDeliveries: arrayOf(object),
  order: objectOf(any),
  rtgDeliveryItems: arrayOf(object),
}

export default DeliverySectionForm
