import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { bool, func, string } from 'prop-types'
import _isEmpty from 'lodash/isEmpty'
import { setDeliverySectionVisited, setShowExpressModal } from '@redux/modules/checkout'
import { Box, Stack, Typography, styled } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import onKeyDownCheck from '@helpers/508/onkeydown-check'
import { setCheckoutStep } from '@helpers/checkout/global'
import { getFromBrowserStorage } from '@helpers/storage'
import { checkoutStepsOrIndex } from '@helpers/checkout/checkout'
import DisabledContext from './disabledContext'

const SectionHeader = styled('div')`
  margin: 0 16px;

  button.edit-btn {
    margin: 0.45em 0 0.45em 0.45em;
    text-transform: uppercase;
    color: #0053a0; // theme.palette.primary.main
    font-size: 16px;
    font-weight: 500;
  }

  button.edit-btn:hover {
    text-decoration: underline;
  }

  button.edit-btn.disabled {
    color: #7e7e7e;
    text-decoration: none;
  }
`

const HeaderStepTitleContainer = styled(Stack, {
  shouldForwardProp: prop => prop !== 'headerIsDisabled',
})(({ headerIsDisabled, theme }) => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: `1px ${headerIsDisabled ? 'rgba(51, 51, 51, 0.38)' : theme.palette.primary.dark} solid`,
  marginBottom: '8px',
  h2: {
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    textTransform: 'capitalize',
    color: headerIsDisabled ? '#757575' : theme.palette.primary.dark,
  },
}))

const HeaderStepNumber = styled(Box, {
  shouldForwardProp: prop => !['completed', 'headerIsDisabled'].includes(prop),
})(({ completed, headerIsDisabled, theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  margin: '8px 8px 8px 0',
  backgroundColor: completed ? theme.palette.success.main : headerIsDisabled ? '#757575' : theme.palette.primary.dark, // eslint-disable-line
  color: theme.palette.common.white,
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '20px',
}))

const HeaderContent = styled(`div`, {
  shouldForwardProp: prop => !['headerIsClickable'].includes(prop),
})(({ headerIsClickable }) => ({
  ':hover': {
    cursor: headerIsClickable ? 'pointer' : 'unset',
  },
  display: 'flex',
  alignItems: 'center',
}))

const CheckoutStepHeader = ({
  checkoutStep,
  completed,
  previousSection,
  sectionNumber,
  sectionTitle,
  sectionType,
  setModal,
}) => {
  const currentIndex = checkoutStepsOrIndex(checkoutStep)
  const dispatch = useDispatch()
  const order = getFromBrowserStorage('session', 'order')
  const { isDisabled } = useContext(DisabledContext)
  const headerIsDisabled = checkoutStep !== sectionType && !completed
  const isNextStep = parseInt(sectionNumber) === currentIndex + 1
  const disabledNextStep = isDisabled && isNextStep
  const disabled = isNextStep ? disabledNextStep : headerIsDisabled

  const isSplitDelivery = !!(order?.splitDeliveryDates?.length ?? false)
  const isSingleVendorItem = order.lineItems?.length === 1 && order.lineItems[0].deliveryType === 'O'
  const stepAfter = { shipping: 'delivery', delivery: 'payment', payment: 'review', review: null }
  const next = stepAfter[checkoutStep]

  const expressDeliveryAvailable = isSplitDelivery
    ? order.splitCalendar?.some(shipment => !_isEmpty(shipment.deliveryCalendar.expressDeliveryDates))
    : !_isEmpty(order.expressCalendar)

  const isSpaceOrEnterKey = key => [' ', 'Enter', 'NumpadEnter', 'Spacebar'].includes(key)
  const handleStep = async event => {
    if (event.type === 'keydown' && !isSpaceOrEnterKey(event.key)) return null

    if (checkoutStep === 'delivery' && !order.isPickup && !isSingleVendorItem) {
      if (
        !expressDeliveryAvailable ||
        (isSplitDelivery && order?.splitDeliveryDates?.some(date => date?.expressDelivery)) ||
        (!isSplitDelivery && order.expressDeliveryDate === order.deliveryDate)
      ) {
        dispatch(setShowExpressModal(false))
      } else {
        dispatch(setShowExpressModal(true))
        return null
      }
    }

    const invalidFields = await setCheckoutStep(event, checkoutStep, next)
    if (invalidFields?.[0] === 'buttonClick') {
      return setModal(true)
    }
    return checkoutStep === 'payment' ? null : dispatch(setDeliverySectionVisited(checkoutStep === 'delivery'))
  }

  const handleClick = Object.keys(stepAfter).includes(checkoutStep) ? handleStep : () => {}
  const handleEdit = event => {
    if (!headerIsClickable) return null
    return setCheckoutStep(event, checkoutStep, sectionType)
  }

  let editIsDisabled

  if (order && order.lineItems) {
    const storeCartLineItems = order.lineItems.filter(lineItem => lineItem.isStoreSku)
    const cartOnlyHasStoreCartItems = storeCartLineItems.length === order.lineItems.length
    editIsDisabled = currentIndex < sectionNumber || (sectionType === 'shipping' && cartOnlyHasStoreCartItems)
  }

  const headerIsClickable = !disabled && parseInt(sectionNumber) !== currentIndex

  return (
    <SectionHeader>
      <HeaderStepTitleContainer direction="row" headerIsDisabled={disabled}>
        <Stack direction="row" alignItems="center">
          <HeaderContent
            onClick={headerIsClickable && editIsDisabled ? handleClick : e => handleEdit(e)}
            onKeyDown={
              headerIsClickable && editIsDisabled ? handleClick : e => onKeyDownCheck(e.keyCode) && handleEdit(e)
            }
            headerIsClickable={headerIsClickable}
          >
            <HeaderStepNumber completed={completed} headerIsDisabled={disabled}>
              {completed ? <CheckIcon /> : sectionNumber}
            </HeaderStepNumber>
            <Typography variant="h2" id={`Step${sectionNumber}Header`} tabIndex="-1">
              {sectionTitle}
            </Typography>
          </HeaderContent>
        </Stack>
        {checkoutStep !== sectionType && !editIsDisabled && (
          <button
            className="edit-btn"
            value="Edit"
            type="button"
            aria-label={
              currentIndex < sectionNumber
                ? `Complete ${previousSection} step before proceeding to ${sectionTitle}`
                : null
            }
            onClick={e => setCheckoutStep(e, checkoutStep, sectionType)}
            onKeyDown={e => onKeyDownCheck(e.keyCode) && setCheckoutStep(e, checkoutStep, sectionType)}
          >
            EDIT <span className="hide508">{sectionTitle}</span>
          </button>
        )}
      </HeaderStepTitleContainer>
    </SectionHeader>
  )
}

CheckoutStepHeader.propTypes = {
  checkoutStep: string,
  completed: bool,
  previousSection: string,
  sectionNumber: string,
  sectionTitle: string,
  sectionType: string,
  setModal: func,
}

export default CheckoutStepHeader
