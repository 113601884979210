import React, { useContext } from 'react'
import { bool, func, number } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import _isEmpty from 'lodash/isEmpty'
import { Button, Grid } from '@mui/material'

import { getDeliverySectionData } from '@helpers/checkout/delivery-section'
import { canContinuePastShipping } from '@helpers/checkout/shipping-section'
import { setCheckoutStep } from '@helpers/checkout/global'
import { setDeliverySectionVisited, setShowExpressModal } from '@redux/modules/checkout'
import loaderLight from '../../../../assets/images/loader-light.svg'
import DisabledContext from './disabledContext'

const isSpaceOrEnterKey = key => [' ', 'Enter', 'NumpadEnter', 'Spacebar'].includes(key)

const buttonText = {
  shipping: 'Continue to Delivery',
  delivery: 'Continue to Payment',
  payment: 'Continue',
  review: 'Place Order',
}

const CheckoutContinueButton = ({ isMobile, handleContinue, handleClose, isFooterBtn, remainingBalance, setModal }) => {
  const dispatch = useDispatch()
  const order = useSelector(state => state.checkout.order)
  const amtDue = useSelector(state => state.checkout.order.amountDue)
  const loading = useSelector(state => state.checkout.loading)
  const step = useSelector(state => state.checkout.checkoutStep)

  const deliveryMode = useSelector(state => state.checkout.deliveryMode)
  const { isDisabled, setIsDisabled } = useContext(DisabledContext)
  const { nonRTGDeliveryItems, rtgDeliveryItems } = getDeliverySectionData(null, [])

  const isSplitDelivery = !!(order?.splitDeliveryDates?.length ?? false)
  const isSingleVendorItem = order.lineItems?.length === 1 && order.lineItems[0].deliveryType === 'O'
  const stepAfter = { shipping: 'delivery', delivery: 'payment', payment: 'review', review: null }
  const next = stepAfter[step]
  const continueButtonText = buttonText[step] ?? 'Continue'

  const expressDeliveryAvailable = isSplitDelivery
    ? order.splitCalendar?.some(shipment => !_isEmpty(shipment.deliveryCalendar.expressDeliveryDates))
    : !_isEmpty(order.expressCalendar)

  const handleStep = async event => {
    if (isFooterBtn) event.stopPropagation()
    if (event.type === 'keydown' && !isSpaceOrEnterKey(event.key)) return null

    if (step === 'delivery' && !order.isPickup && !isSingleVendorItem) {
      if (
        !expressDeliveryAvailable ||
        (isSplitDelivery && order?.splitDeliveryDates?.some(date => date?.expressDelivery)) ||
        (!isSplitDelivery && order.expressDeliveryDate === order.deliveryDate)
      ) {
        dispatch(setShowExpressModal(false))
      } else {
        dispatch(setShowExpressModal(true))
        return null
      }
    }

    const invalidFields = await setCheckoutStep(event, step, next)
    if (invalidFields?.[0] === 'buttonClick' && setModal) {
      return setModal(true)
    }
    if (isMobile) handleClose(false)
    return step === 'payment' ? null : dispatch(setDeliverySectionVisited(step === 'delivery'))
  }

  const handleClick = Object.keys(stepAfter).includes(step) ? handleStep : handleContinue ?? (() => {})

  // do not allow passing Delivery step until a deliveryMode option is selected...
  if (step === 'shipping') {
    setIsDisabled(!canContinuePastShipping())
  } else if (step === 'delivery') {
    setIsDisabled(rtgDeliveryItems.length > 0 && !deliveryMode)
    // ...except if order has only non-RTG delivery items, then do allow continuing
    if (isDisabled) {
      if (rtgDeliveryItems.length === 0) {
        Object.keys(nonRTGDeliveryItems).forEach(type => {
          if (nonRTGDeliveryItems[type].length > 0) setIsDisabled(false)
        })
      }
    }
  } else if (step === 'payment') {
    setIsDisabled(remainingBalance > 0 || amtDue > 0)
  } else {
    setIsDisabled(false)
  }

  return (
    <Grid
      justifyContent={{ xs: 'flex-end', md: 'center' }}
      sx={{ '.Mui-disabled': { color: 'black !important' } }}
      pl={{ xs: 0, md: step === 'shipping' && 3 }}
      py={{ xs: 0, md: 3 }}
    >
      {isDisabled && (
        <button
          type="button"
          className="hide508"
          aria-label={`Continue to ${stepAfter[step]} section button, please complete required fields to continue`}
        />
      )}
      <Button
        disabled={isDisabled}
        onClick={!loading ? handleClick : null}
        onKeyDown={!loading ? handleClick : null}
        sx={{ minWidth: '257px', paddingX: '2.5rem' }}
        variant="contained"
        inputProps={{
          tabIndex: '0',
          'aria-label': isDisabled ? 'Continue button, please complete required fields' : 'Continue button',
        }}
        tabindex={0}
        aria-label={`Continue to ${stepAfter[step]} section button.`}
      >
        {loading ? <img style={{ height: 28 }} alt={`Submitting ${step}`} src={loaderLight} /> : continueButtonText}
      </Button>
    </Grid>
  )
}

CheckoutContinueButton.propTypes = {
  isMobile: bool,
  handleContinue: func,
  handleClose: func,
  isFooterBtn: bool,
  remainingBalance: number,
  setModal: func,
}

export default CheckoutContinueButton
