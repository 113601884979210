import React from 'react'
import PropTypes, { bool } from 'prop-types'
import styled from 'styled-components'
import { Button, Checkbox, FormControlLabel, Stack, TextField, Typography } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import { stateSelectList } from '@helpers/geo-location'
import { setPayerInfo } from '@helpers/checkout/payment-section/payment-section'
import {
  setBillingAddressInfo,
  onBillingStateChange,
  onChangeBillingDifferent,
} from '@helpers/checkout/payment-section/billing-address'
import AddressSuggestionModal from '../../address-suggestion-modal'

const BillingContainer = styled.div`
  padding-top: 1em;
  .billing {
    text-transform: capitalize;
    margin-top: 1rem;
    .iconWrapper {
      margin-top: 2px;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px;
      background-color: rgba(0, 83, 160, 0.04);
      border-radius: 50%;
    }
  }
`

const BillingAddress = ({ clearInvalidFields, invalidFields, loading, order, setBillingState }) => {
  const { billingAddress, payer } = order
  const suggestModal = invalidFields.length === 1 && invalidFields.includes('unable to verify')
  const showBillingAddressForm = payer.billingDifferent && !payer.billingSubmitted
  const showCondensedBillingAddress = payer.billingDifferent && payer.billingSubmitted && billingAddress.address1

  const handleCheckboxClick = e => {
    clearInvalidFields()
    onChangeBillingDifferent(!e.target.checked, order)
  }

  return (
    <BillingContainer>
      {suggestModal && (
        <AddressSuggestionModal
          address={billingAddress}
          closeModal={clearInvalidFields}
          modalOpen={suggestModal}
          setBillingState={setBillingState}
          suggestion={order.suggestedAddress}
        />
      )}

      <h4>Billing Address</h4>
      <FormControlLabel
        sx={{ alignItems: 'center' }}
        control={
          <Checkbox
            checked={!payer.billingDifferent}
            disabled={loading}
            onChange={handleCheckboxClick}
            inputProps={{ 'aria-label': 'Check if billing address is the same as shipping' }}
            sx={{ ml: 1.5, mb: 1.2 }}
          />
        }
        label={<Typography sx={{ textAlign: 'left' }}>Same as shipping address</Typography>}
      />

      {showBillingAddressForm && (
        <Stack alignItems="flex-start" component="form" gap="20px" pt={2}>
          <TextField
            disabled={loading}
            fullWidth
            error={invalidFields?.includes('firstName')}
            FormHelperTextProps={{ style: { fontSize: 12, fontWeight: 400 } }}
            helperText={invalidFields?.includes('firstName') ? `* Please enter a valid First Name` : null}
            id="billing-first-name"
            label={
              <>
                First Name <span style={{ fontSize: 18, color: '#E11F21' }}>*</span>
              </>
            }
            onChange={e => setPayerInfo(e.target.value, 'firstName')}
            value={payer.firstName ?? ''}
            autoComplete="given-name"
            aria-label="Billing Address first name"
            aria-required="true"
          />
          <TextField
            disabled={loading}
            fullWidth
            error={invalidFields?.includes('lastName')}
            FormHelperTextProps={{ style: { fontSize: 12, fontWeight: 400 } }}
            helperText={invalidFields?.includes('lastName') ? `* Please enter a valid Last Name` : null}
            id="billing-last-name"
            label={
              <>
                Last Name <span style={{ fontSize: 18, color: '#E11F21' }}>*</span>
              </>
            }
            onChange={e => setPayerInfo(e.target.value, 'lastName')}
            value={payer.lastName ?? ''}
            autoComplete="family-name"
            aria-label="Billing Address last name"
            aria-required="true"
          />
          <TextField
            disabled={loading}
            fullWidth
            error={invalidFields?.includes('address1')}
            FormHelperTextProps={{ style: { fontSize: 12, fontWeight: 400 } }}
            helperText={invalidFields?.includes('address1') ? `* Please enter a valid Street Address` : null}
            id="billing-address1"
            label={
              <>
                Address Line 1 <span style={{ fontSize: 18, color: '#E11F21' }}>*</span>
              </>
            }
            onChange={e => setBillingAddressInfo(e.target.value, 'address1')}
            value={billingAddress.address1 ?? ''}
            aria-label="Billing Address address 1"
            aria-required="true"
          />
          <TextField
            disabled={loading}
            fullWidth
            id="billing-address2"
            label="Address Line 2"
            onChange={e => setBillingAddressInfo(e.target.value, 'address2')}
            value={billingAddress.address2 ?? ''}
            aria-label="Billing Address address 2"
          />
          <TextField
            disabled={loading}
            fullWidth
            error={invalidFields?.includes('city')}
            FormHelperTextProps={{ style: { fontSize: 12, fontWeight: 400 } }}
            helperText={invalidFields?.includes('city') ? `* Please enter a valid City` : null}
            id="billing-city"
            label={
              <>
                City <span style={{ fontSize: 18, color: '#E11F21' }}>*</span>
              </>
            }
            onChange={e => setBillingAddressInfo(e.target.value, 'city')}
            value={billingAddress.city ?? ''}
            aria-label="Billing Address City"
            aria-required="true"
          />
          <Stack direction={{ xs: 'column', sm: 'row' }} gap={2}>
            <TextField
              disabled={loading}
              error={invalidFields?.includes('state')}
              id="billing-state"
              FormHelperTextProps={{ style: { fontSize: 12, fontWeight: 400 } }}
              helperText={invalidFields?.includes('state') ? `* Please select a State` : null}
              label={
                <>
                  State <span style={{ fontSize: 18, color: '#E11F21' }}>*</span>
                </>
              }
              onChange={onBillingStateChange}
              value={billingAddress?.state?.toUpperCase() ?? ''}
              select
              sx={{ width: 104 }}
              SelectProps={{
                native: true,
                inputProps: {
                  style: { paddingBottom: '15px', paddingTop: '15px' },
                  'aria-label': 'Billing Address State',
                },
              }}
            >
              <option aria-label="None" value="" />
              {stateSelectList.map(st => (
                <option key={`state_dropdown_item_${st[1]}`} value={st[1]} aria-label={`${st[0]}`}>
                  {st[1]}
                </option>
              ))}
            </TextField>

            <TextField
              disabled={loading}
              error={invalidFields?.includes('zip')}
              FormHelperTextProps={{ style: { fontSize: 12, fontWeight: 400 } }}
              helperText={invalidFields?.includes('zip') ? `* Please enter a valid Zip Code` : null}
              id="billing-zip"
              label={
                <>
                  Zip Code <span style={{ fontSize: 18, color: '#E11F21' }}>*</span>
                </>
              }
              onChange={e => setBillingAddressInfo(e.target.value, 'zip')}
              value={billingAddress.zip ?? ''}
              aria-label="Billing Address zip code"
              aria-required="true"
            />
          </Stack>
        </Stack>
      )}

      {showCondensedBillingAddress && (
        <div className="billing">
          <Stack direction="row" justifyContent={{ xs: 'space-between', sm: 'flex-start' }} alignItems="center">
            <h4>Billing Information</h4>
            <Button aria-label="Edit Billing Address" onClick={() => setPayerInfo(false, 'billingSubmitted')}>
              Edit
            </Button>
          </Stack>
          <Stack direction="row" gap={1}>
            <div className="iconWrapper">
              <PersonIcon htmlColor="rgba(0, 83, 160, 0.3)" />
            </div>
            <div>
              {`${payer.firstName} ${payer.lastName}`}
              <br />
              {billingAddress.address1}
              {billingAddress.address2 &&
                billingAddress.address2 !== '' &&
                billingAddress.address2 !== 'null' &&
                billingAddress.address2 !== 'undefined' &&
                ` ${billingAddress.address2}`}
              <br />
              {`${billingAddress.city} `}
              {`${billingAddress.state} `}
              {billingAddress.zip}
            </div>
          </Stack>
        </div>
      )}
    </BillingContainer>
  )
}

BillingAddress.propTypes = {
  clearInvalidFields: PropTypes.any,
  invalidFields: PropTypes.any,
  loading: bool,
  order: PropTypes.any,
  setBillingState: PropTypes.any,
}

export default BillingAddress
