import React, { useState } from 'react'
import { array, func, number, string } from 'prop-types'
import { Dialog } from '@material-ui/core'
import _isEmpty from 'lodash/isEmpty'

import WellsFargoConsent from './wells-fargo-consent'
import WellsFargoModal from './wells-fargo-modal'
import WellsFargoModalPartial from './wells-fargo-partial'

const initialInfo = {
  accountNumber: '',
  hasPayments: false,
  planNumber: '',
  uvFinancePlanCode: '',
  zipcode: '',
}

const WellsFargo = ({
  amountDue,
  closeModals,
  downPaymentAmount,
  emailAddress,
  financePlans,
  orderId,
  paymentInfo,
}) => {
  const [activeModal, setActiveModal] = useState('initial')
  const [loadingModal, setLoadingModal] = useState(false)
  const [wfInfo, setWfInfo] = useState(initialInfo)
  const [availableCredit, setAvailableCredit] = useState(amountDue)

  const { hasPayments: isDownPaymentRequired } = wfInfo
  const downPayment = `$${downPaymentAmount.toFixed(2)}`

  const actualAmountDue = Math.round((amountDue - (isDownPaymentRequired ? downPaymentAmount : 0)) * 100) / 100
  const financeAmount = Math.min(availableCredit, actualAmountDue)

  const updateModal = (OTB, newInfo) => {
    setWfInfo(newInfo)
    setAvailableCredit(OTB)
    setActiveModal(OTB < actualAmountDue ? 'partial' : 'consent')
    setLoadingModal(false)
  }

  const handleCancel = () => {
    setWfInfo(initialInfo)
    closeModals()
  }

  return (
    <Dialog
      onClose={closeModals}
      aria-labelledby="payment-option-wells-fargo"
      open
      id="payment-modal-wells-fargo"
      tabIndex="0"
      className="rooms-to-go-credit-container"
      fullWidth
      PaperProps={{ style: { margin: '12px', width: 'calc(100% - 24px)', maxWidth: '500px' } }}
    >
      {activeModal === 'initial' && (
        <WellsFargoModal
          closeModal={closeModals}
          downPayment={downPayment}
          loading={loadingModal}
          orderId={orderId}
          plans={financePlans}
          setLoading={setLoadingModal}
          setWfInfo={setWfInfo}
          updateModal={updateModal}
          wfInfo={wfInfo}
        />
      )}

      {activeModal === 'partial' && (
        <WellsFargoModalPartial
          availableAmount={availableCredit}
          amountDue={actualAmountDue}
          loading={loadingModal}
          onCancel={handleCancel}
          setActiveModal={setActiveModal}
          setLoading={setLoadingModal}
        />
      )}

      {activeModal === 'consent' && (
        <WellsFargoConsent
          downPayment={isDownPaymentRequired ? downPayment : ''}
          loading={loadingModal}
          handleBack={() => setActiveModal('initial')}
          handleCancel={handleCancel}
          payment={`$${financeAmount.toFixed(2)}`}
          promoBody={{ accountNumber: wfInfo.accountNumber, emailAddress, orderId, planNumber: wfInfo.planNumber }}
          setLoading={setLoadingModal}
          wfPaymentBody={{
            orderId,
            paymentInfo: [...paymentInfo, { paymentType: 'WFG', paymentProperties: { ...wfInfo } }],
          }}
        />
      )}
    </Dialog>
  )
}

WellsFargo.propTypes = {
  amountDue: number,
  closeModals: func,
  downPaymentAmount: number,
  emailAddress: string,
  financePlans: array,
  orderId: string,
  paymentInfo: array,
}

export default WellsFargo
