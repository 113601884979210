import React from 'react'
import { func } from 'prop-types'
import { DialogTitle, IconButton, Stack, Typography } from '@mui/material'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import PaymentSvg from '@shared/svgs/paymentSvgComp'

const WellsFargoModalTitle = ({ handleBack }) => (
  <DialogTitle id="payment-modal-title" component="div" sx={{ p: handleBack ? '16px 24px 16px 0' : '16px 24px' }}>
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row" alignItems="center">
        {handleBack && (
          <IconButton onClick={handleBack}>
            <ArrowBackIosNewIcon />
          </IconButton>
        )}
        <Stack>
          <Typography variant="h2" sx={{ fontSize: 19, fontWeight: 600, textTransform: 'capitalize' }}>
            Rooms To Go Credit Card
          </Typography>
          <Typography sx={{ fontSize: 16, fontWeight: 400 }}> Issued by Wells Fargo </Typography>
        </Stack>
      </Stack>
      <PaymentSvg cordY="0" vpHeight={50} width={60} uniqueNameForId="paymentWellsFargo" cards={['wfg']} />
    </Stack>
  </DialogTitle>
)

WellsFargoModalTitle.propTypes = {
  handleBack: func,
}

export default WellsFargoModalTitle
