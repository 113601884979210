import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Global } from '@emotion/react'
import { styled } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { grey } from '@mui/material/colors'
import { Box, Divider, Grid, IconButton, Stack, SwipeableDrawer, Typography, CircularProgress } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { BitpayPayment, ReviewType } from '@components/checkout/checkout-parts/checkout-steps/types'
import CheckoutContinueButton from '@components/checkout/checkout-parts/checkout-steps/checkout-continue-button'
import ShippingErrorModal from '@components/checkout/checkout-parts/shipping-section/shipping-error-modal'
import ProductLineItem from '../../../molecules/ProductLineItem/ProductLineItem'
import PriceAtom from '../../../atoms/ProductPrice/PriceAtom'
import TotalItems from './TotalItems'

const Root = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor: theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}))

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}))

const ExpandMore = styled(IconButton, { shouldForwardProp: prop => prop !== 'isExpanded' })(
  ({ isExpanded, theme }) => ({
    color: theme.palette.primary.main,
    transform: isExpanded ? 'rotate(0deg)' : 'rotate(180deg)',
    margin: '0 auto',
    padding: 0,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }),
)

const ExpandMoreText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '14px',
  textDecoration: 'underline',
  margin: '0 4px',
}))

const StyledTitle = styled(Typography, { shouldForwardProp: prop => prop !== 'isExpanded' })(({ isExpanded }) => ({
  fontSize: isExpanded ? 19 : 12,
  fontWeight: isExpanded ? 600 : 400,
  lineHeight: isExpanded ? '26px' : '16px',
  display: !isExpanded && 'none',
  color: '#003566',
}))

const StyledTotalItemGrid = styled(Grid)(({ theme }) => ({
  paddingBttom: '8px',
  '&:last-of-type': {
    marginBottom: `${theme.spacing(1)} !important`,
  },
}))

const StyledHeading = styled(Typography)(({ theme, open }) => ({
  fontSize: open ? '23px' : '18px',
  marginRight: !open && '8px',
  fontWeight: 500,
  color: theme.palette.primary.dark,
}))

const StyledDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  height: '1px',
  margin: '5px 0',
}))

const StyledMutedDivider = styled(Divider)(() => ({
  backgroundColor: '#dedede',
  height: '1px',
  margin: '10px 0',
}))

const StyledDividerGrid = styled(Grid)(() => ({
  paddingBottom: 15,
}))

const StyledLineItems = styled(Grid)(() => ({
  paddingBottom: 15,
  maxHeight: 225,
  overflowY: 'scroll',
}))

const BtnContainer = styled(Grid)(() => ({
  '& button': {
    marginTop: '12px',
    width: '100%',
  },
}))

const MobileOrderSummary = ({
  dtIdPrefix,
  isShippingStep,
  loading,
  missingDataMessage,
  order,
  productLineItems,
  showContinueBtn,
  total,
  totalLineItems,
}) => {
  // Start Order Summary opened on first load
  const [open, setOpen] = useState(false)
  const [hasCollapsed, sethasCollapsed] = useState(true)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (!open) sethasCollapsed(true)
  }, [open, hasCollapsed])

  const toggleDrawer = newOpen => () => {
    setOpen(newOpen)
  }

  const handleExpandMoreClick = e => {
    e.stopPropagation()
    setOpen(currentState => !currentState)
  }

  const ExpandComponent = () => (
    <Stack direction="row">
      <ExpandMoreText>{open ? 'Hide Details' : 'Show Details'}</ExpandMoreText>
      <ExpandMore isExpanded={open} onClick={handleExpandMoreClick} aria-expanded={open} aria-label="show more">
        <ExpandMoreIcon />
      </ExpandMore>
    </Stack>
  )

  const drawerBleeding = 105 // how much of the drawer we want to "peek" out from the bottom

  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            overflow: 'visible',
            minHeight: open ? 'auto' : '480px',
          },
        }}
      />
      {showModal && <ShippingErrorModal showModal={showModal} onCloseModal={() => setShowModal(false)} />}
      <SwipeableDrawer
        data-testid={`${dtIdPrefix}container-${open ? 'open' : 'closed'}`}
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        onClick={() => setOpen(currentState => !currentState)}
        allowSwipeInChildren
        disableSwipeToOpen
        swipeAreaWidth={open ? 65 : drawerBleeding}
        ModalProps={{ keepMounted: true }}
      >
        <StyledBox
          sx={{
            position: 'absolute',
            bottom: open ? 'unset' : 0,
            height: open ? 'auto' : '475px',
            top: open ? -72 : -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: 'visible',
            right: 0,
            left: 0,
            padding: `10px 15px ${open ? '10px' : '20px'} 15px`,
            pointerEvents: 'all',
            boxShadow: !open && '0px -1px 3px rgba(0, 0, 0, 0.4)',
          }}
        >
          {open ? (
            <>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <StyledTitle id="order-sum" isExpanded={open}>
                  Order Summary
                </StyledTitle>
                <ExpandComponent />
              </Stack>
              <StyledDividerGrid item>
                <StyledDivider />
              </StyledDividerGrid>
            </>
          ) : (
            <>
              <StyledTotalItemGrid container justifyContent="space-between" alignItems="center">
                <Stack direction="row">
                  <StyledHeading open={open} data-testid={`${dtIdPrefix}title-closed`}>
                    Total:{' '}
                  </StyledHeading>
                  {loading ? (
                    <Box sx={{ display: 'flex', width: '3rem', alignItems: 'center', justifyContent: 'center' }}>
                      <CircularProgress size="1rem" />
                    </Box>
                  ) : (
                    <StyledHeading open={open} data-testid={`${dtIdPrefix}total-price-closed`}>
                      <PriceAtom suppressCentsIfZero price={total} />
                    </StyledHeading>
                  )}
                </Stack>
                <ExpandComponent />
              </StyledTotalItemGrid>

              {!loading && (
                <Grid container item direction="row" alignItems="center" justifyContent="space-between">
                  {showContinueBtn ? (
                    <BtnContainer item xs={12} sx={{ mb: '20px' }}>
                      <CheckoutContinueButton
                        isMobile
                        isFooterBtn
                        setModal={isShippingStep ? setShowModal : null}
                        handleClose={setOpen}
                      />
                    </BtnContainer>
                  ) : (
                    <BtnContainer item xs={12} sx={{ mb: '20px' }}>
                      {order?.selectedPaymentType === 'bitpay' ? (
                        <BitpayPayment order={order} />
                      ) : (
                        <ReviewType isMobile />
                      )}
                    </BtnContainer>
                  )}
                </Grid>
              )}
            </>
          )}
        </StyledBox>
        <StyledLineItems item xs={12}>
          {productLineItems?.map(
            ({ id, title, image, price, protectionPlanPrice, protectionPlanSelected, quantity, addOns }) => (
              <ProductLineItem
                key={id}
                image={image}
                title={title}
                price={price}
                quantity={quantity}
                protectionPlanPrice={protectionPlanPrice}
                protectionPlanSelected={protectionPlanSelected}
                addOns={addOns}
                dtIdPrefix={dtIdPrefix}
              />
            ),
          )}
        </StyledLineItems>
        <Box sx={{ padding: '0px 15px' }}>
          <StyledMutedDivider />

          <TotalItems
            totalLineItems={totalLineItems}
            loading={loading}
            missingDataMessage={missingDataMessage}
            dtIdPrefix={dtIdPrefix}
          />

          {open && (
            <>
              <StyledMutedDivider />
              <StyledTotalItemGrid
                data-testid={`${dtIdPrefix}total-container-open`}
                container
                justifyContent="space-between"
              >
                <StyledHeading open={open} data-testid={`${dtIdPrefix}title-open`} textTransform="uppercase">
                  Total
                </StyledHeading>
                <StyledHeading open={open} data-testid={`${dtIdPrefix}total-price-open`}>
                  <PriceAtom suppressCentsIfZero price={total} />
                </StyledHeading>
              </StyledTotalItemGrid>

              {!loading && (
                <Grid container item direction="row" alignItems="center" justifyContent="space-between">
                  {showContinueBtn ? (
                    <BtnContainer item xs={12} sx={{ mb: '20px' }}>
                      <CheckoutContinueButton
                        isMobile
                        isFooterBtn
                        setModal={isShippingStep ? setShowModal : null}
                        handleClose={setOpen}
                      />
                    </BtnContainer>
                  ) : (
                    <BtnContainer item xs={12} sx={{ mb: '20px' }}>
                      {order?.selectedPaymentType === 'bitpay' ? (
                        <BitpayPayment order={order} />
                      ) : (
                        <ReviewType isMobile />
                      )}
                    </BtnContainer>
                  )}
                </Grid>
              )}
            </>
          )}
        </Box>
      </SwipeableDrawer>
    </Root>
  )
}

MobileOrderSummary.propTypes = {
  dtIdPrefix: PropTypes.string,
  isShippingStep: PropTypes.bool,
  missingDataMessage: PropTypes.string,
  order: PropTypes.object,
  productLineItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      title: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      protectionPlanSelected: PropTypes.bool,
      protectionPlanPrice: PropTypes.number,
      addOns: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          price: PropTypes.number,
        }),
      ),
    }),
  ),
  total: PropTypes.number,
  totalLineItems: PropTypes.arrayOf(
    PropTypes.shape({
      isDiscount: PropTypes.bool,
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      subheading: PropTypes.string.isRequired,
    }),
  ),
  loading: PropTypes.bool,
  showContinueBtn: PropTypes.bool,
}

export default MobileOrderSummary
