import React from 'react'
import { bool, func, number } from 'prop-types'
import { Button, DialogActions, DialogContent, Stack, Typography } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import loaderLight from '@assets/images/loader-light.svg'
import { LoadingSpinner } from '../styles'
import WellsFargoModalTitle from './wells-fargo-modal-title'
import PriceAtom from '../../../../../@rtg2022/components/atoms/ProductPrice/PriceAtom'

const WellsFargoModalPartial = ({ amountDue, availableAmount, loading, onCancel, setActiveModal, setLoading }) => {
  const handleContinue = () => {
    setLoading(true)

    setTimeout(() => setActiveModal('consent'), 500)
  }

  const handleBack = () => setActiveModal('initial')

  return (
    <>
      <WellsFargoModalTitle handleBack={handleBack} />

      <DialogContent style={{ paddingTop: '8px' }}>
        <Stack spacing={2}>
          <Stack direction="row" spacing={1} alignItems="center">
            <ErrorOutlineIcon color="error" />
            <Typography color="error" sx={{ fontWeight: 500 }}>
              Your available credit is less than the amount due.
            </Typography>
          </Stack>
          <Typography sx={{ fontWeight: 500, pl: 4 }}>
            Amount Due: <PriceAtom price={amountDue} />
            <br />
            Available Credit: <PriceAtom price={availableAmount} />
          </Typography>
          <Typography>
            Click CONTINUE to apply your available credit as a partial payment, and then choose another payment type to
            cover the remaining balance (plus any required down payment).
          </Typography>
          <Typography>Click CANCEL to choose a different payment type.</Typography>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'center', padding: '24px' }}>
        <Button fullWidth variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button fullWidth variant="contained" onClick={handleContinue} sx={{ height: '40px' }}>
          {!loading && 'Continue'}
          {loading && <LoadingSpinner alt="Submitting Wells Fargo financing request" src={loaderLight} />}
        </Button>
      </DialogActions>
    </>
  )
}

WellsFargoModalPartial.propTypes = {
  availableAmount: number,
  amountDue: number,
  loading: bool,
  onCancel: func,
  setActiveModal: func,
  setLoading: func,
}

export default WellsFargoModalPartial
