import React from 'react'
import { array } from 'prop-types'
import _isEmpty from 'lodash/isEmpty'
import { Stack, Typography, styled } from '@mui/material'
import { decodeHtml } from '@helpers/string-helper'
import { colors } from '@constants/styles'

const ProductDetails = styled('div')({
  color: colors.darkerGrey,
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  flexDirection: 'column',
  p: {
    fontSize: '14px',
    fontWeight: '400',
  },
  span: {
    fontSize: '12px',
  },
})

const ProductThumbnail = styled('div')({
  width: '75px',
  display: 'flex',
  backgroundColor: '#f6f6f6',
  justifyContent: 'center',
  img: {
    width: '45px !important',
    height: 'auto',
    padding: '8px 0',
  },
})

const EligibleForExpress = styled(Typography)({
  fontSize: '12px',
  color: '#333333B2',
  paddingBottom: '5px',
})

const SplitExpressItems = ({ items }) => (
  <div style={{ marginBottom: '24px' }}>
    <EligibleForExpress>Eligible for Express Delivery:</EligibleForExpress>
    {items.map(item => {
      const image = item?.product?.grid_image || item?.product?.primary_image
      return (
        <Stack key={item?.sku} direction="row" mb="0.5rem" spacing={2}>
          {image && (
            <Stack justifyContent="flex-start">
              <ProductThumbnail>
                <img src={`${image}${item?.sku}`} alt={item?.product?.title} role="presentation" aria-hidden="true" />
              </ProductThumbnail>
            </Stack>
          )}
          <ProductDetails>
            <p>{item?.product?.title ? decodeHtml(item?.product?.title) : `SKU: ${item?.sku} `}</p>
          </ProductDetails>
        </Stack>
      )
    })}
  </div>
)

SplitExpressItems.propTypes = {
  items: array,
}

export default SplitExpressItems
