import React, { useState } from 'react'
import { array, bool, func, object, string } from 'prop-types'
import {
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  styled,
} from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import loaderLight from '@assets/images/loader-light.svg'
import { getWellsFargoOTB } from '@services/checkout'
import { LoadingSpinner, WFTextInput } from '../styles'
import WellsFargoModalTitle from './wells-fargo-modal-title'

const RadioLabel = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  '&.Mui-focused': { color: theme.palette.secondary.dark },
}))

const isNumeric = char => ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(char)
const insertDashesInAccountNum = num => {
  if (!num || num.length < 5) return num

  if (num.length < 9) return `${num.slice(0, 4)}-${num.slice(4)}`

  if (num.length < 13) return `${num.slice(0, 4)}-${num.slice(4, 8)}-${num.slice(8)}`

  return `${num.slice(0, 4)}-${num.slice(4, 8)}-${num.slice(8, 12)}-${num.slice(12)}`
}

const getErrorMsg = type => {
  switch (type) {
    case 'input':
      return 'Please confirm that your Account Number and/or Billing Zip Code are correct.'
    case 'insufficent':
      return 'You do not have enough available credit on this account. Please try another payment method.'
    default:
      return 'An error occurred while updating your payment. Please try again later or choose a different payment method.'
  }
}

const PlanLabel = ({ downPayment, plan }) => (
  <Stack>
    <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>{plan.siteFriendlyLabel}</Typography>
    {plan.downPaymentRequired ? (
      <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>
        {`Small down payment required at the time of purchase: ${downPayment} (shipping & sales tax)`}
      </Typography>
    ) : (
      <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>No down payment required</Typography>
    )}
  </Stack>
)

PlanLabel.propTypes = {
  downPayment: string,
  plan: object,
}

const WellsFargoModal = ({
  closeModal,
  downPayment,
  loading,
  orderId,
  plans,
  setLoading,
  setWfInfo,
  updateModal,
  wfInfo,
}) => {
  const [error, setError] = useState('')
  const [accountNumber, setAccountNumber] = useState(wfInfo.accountNumber)
  const [zipcode, setZipcode] = useState(wfInfo.zipcode)
  const [planNumber, setPlanNumber] = useState(null)
  const [invalidFields, setInvalidFields] = useState([])

  const canContinue =
    accountNumber.replaceAll('-', '').length === 16 && zipcode && planNumber && invalidFields.length === 0

  const handleAccountNumberChange = val => {
    let newNum = (val?.trim() ?? '').replaceAll('-', '')
    if (!newNum.split('').every(x => isNumeric(x)) || newNum.length > 16) return null

    if (invalidFields.includes('accountNumber')) setInvalidFields([])
    if (!newNum) return setAccountNumber('')

    newNum = insertDashesInAccountNum(newNum)
    return setAccountNumber(newNum)
  }

  const handleZipCodeChange = val => {
    const newZip = val?.trim() ?? ''
    if (!newZip.split('').every(x => isNumeric(x))) return null

    if (invalidFields.includes('zipcode')) setInvalidFields([])
    if (!newZip) return setZipcode('')

    return setZipcode(newZip)
  }

  const handleSelect = newPlanNum => {
    setPlanNumber(newPlanNum)
    const { downPaymentRequired, financeCode } = plans?.find(p => p.serviceFinanceCode === newPlanNum)
    setWfInfo(currentInfo => ({
      ...currentInfo,
      hasPayments: downPaymentRequired,
      planNumber: newPlanNum,
      uvFinancePlanCode: financeCode,
    }))
  }

  const handleContinue = () => {
    setLoading(true)
    const acct = accountNumber.replaceAll('-', '')
    const body = { accountNumber: acct, orderId, zipcode }
    const { downPaymentRequired: hasPayments, financeCode: uvFinancePlanCode } = plans?.find(
      p => p.serviceFinanceCode === planNumber,
    )

    getWellsFargoOTB(body)
      .then(({ availableCredit }) => {
        const OTB = Number(availableCredit)

        if (OTB <= 0 || Number.isNaN(OTB)) {
          setError(getErrorMsg('insufficent'))
          setLoading(false)
        } else {
          updateModal(OTB, { accountNumber: acct, hasPayments, planNumber, uvFinancePlanCode, zipcode })
        }
      })
      .catch(err => {
        const errorStatus = err?.response?.status
        // console.error('Error updating payment type >> ', err)
        if ((errorStatus === 400 || errorStatus === 422) && err?.response?.data) {
          // A 400 or 422 error indicates bad data entered by user
          const errors = err?.response?.data?.[0]?.error ?? []
          const errorMsg = err?.response?.data?.error?.message ?? ''

          if (errors.some(e => (e?.path ?? '').includes('zip'))) {
            setInvalidFields(current => [...current, 'zipcode'])
          } else if (errors.some(e => (e?.path ?? '').includes('account')) || errorMsg.includes('WFG')) {
            setInvalidFields(current => [...current, 'accountNumber'])
          } else {
            setInvalidFields(current => [...current, 'accountNumber', 'zipcode'])
          }
        } else {
          setError(getErrorMsg())
        }
        setLoading(false)
      })
  }

  return (
    <>
      <WellsFargoModalTitle />

      <DialogContent style={{ paddingTop: '8px' }}>
        {error ? (
          <Stack spacing={1}>
            {error.includes('available credit') && (
              <Stack direction="row" spacing={1} alignItems="center">
                <ErrorOutlineIcon color="error" />
                <Typography color="error" sx={{ fontWeight: 500 }}>
                  Not enough credit available
                </Typography>
              </Stack>
            )}
            <Typography>{error}</Typography>
          </Stack>
        ) : (
          <>
            <Typography sx={{ textAlign: 'left', fontWeight: 400, pb: 2 }}>
              Enter your 16-digit credit card account number and billing zip code, then select a promotion below:
            </Typography>
            <Stack gap={2} pb={3}>
              <WFTextInput
                autoComplete="cc-number"
                fullWidth
                id="account-number"
                label="Credit Card Number"
                onChange={e => handleAccountNumberChange(e.target.value)}
                size="small"
                sx={{ width: '250px' }}
                value={accountNumber}
                error={invalidFields.includes('accountNumber')}
                FormHelperTextProps={{ style: { fontSize: 12, fontWeight: 600 } }}
                helperText={invalidFields.includes('accountNumber') ? 'Please confirm your Account Number' : null}
              />

              <WFTextInput
                autoComplete="postal-code"
                fullWidth
                id="zipcode"
                label="Billing Zip Code"
                onChange={e => handleZipCodeChange(e.target.value)}
                size="small"
                sx={{ width: '170px' }}
                value={zipcode}
                error={invalidFields.includes('zipcode')}
                FormHelperTextProps={{ style: { fontSize: 12, fontWeight: 600, textWrap: 'nowrap' } }}
                helperText={invalidFields.includes('zipcode') ? 'Please confirm your Zip Code' : null}
              />
            </Stack>
            <FormControl sx={{ width: 'fit-content' }}>
              <RadioLabel id="finance-plan-radio-buttons-group">Select a Promotion:</RadioLabel>
              <RadioGroup
                aria-labelledby="finance-plan-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={planNumber}
                onChange={e => handleSelect(e.target.value)}
                sx={{ ml: { xs: '4px', sm: '10px' } }}
              >
                <FormControlLabel
                  value={plans[0].serviceFinanceCode}
                  sx={{ ml: 0, mr: 0, mb: 2, alignItems: 'flex-start' }}
                  control={<Radio size="small" sx={{ padding: { xs: '4px', sm: '7px' } }} />}
                  label={<PlanLabel downPayment={downPayment} plan={plans[0]} />}
                />
                <FormControlLabel
                  value={plans[1].serviceFinanceCode}
                  sx={{ ml: 0, mr: 0, alignItems: 'flex-start' }}
                  control={<Radio size="small" sx={{ padding: { xs: '4px', sm: '7px' } }} />}
                  label={<PlanLabel downPayment={downPayment} plan={plans[1]} />}
                />
              </RadioGroup>
            </FormControl>
          </>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'center', padding: '24px' }}>
        <Button fullWidth variant={error ? 'contained' : 'outlined'} onClick={!loading ? closeModal : null}>
          {error ? 'Close' : 'Cancel'}
        </Button>
        {!error && (
          <Button
            disabled={error || !canContinue}
            sx={{ height: '40px' }}
            fullWidth
            variant="contained"
            onClick={!loading ? handleContinue : null}
          >
            {!loading && 'Continue'}
            {loading && <LoadingSpinner alt="Submitting Wells Fargo financing request" src={loaderLight} />}
          </Button>
        )}
      </DialogActions>
    </>
  )
}

WellsFargoModal.propTypes = {
  closeModal: func,
  downPayment: string,
  loading: bool,
  orderId: string,
  plans: array,
  setLoading: func,
  setWfInfo: func,
  updateModal: func,
  wfInfo: object,
}

export default WellsFargoModal
