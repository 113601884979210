import React from 'react'

const months = [
  '01 - Jan',
  '02 - Feb',
  '03 - Mar',
  '04 - Apr',
  '05 - May',
  '06 - Jun',
  '07 - Jul',
  '08 - Aug',
  '09 - Sep',
  '10 - Oct',
  '11 - Nov',
  '12 - Dec',
]

const MonthSelect = () => (
  <select id="cardExpirationMonth" name="ccmonth" aria-label="Card expiration month">
    {months.map(month => (
      <option value={month.substring(0, 2)} key={month}>
        {month}
      </option>
    ))}
  </select>
)

const YearSelect = () => {
  const currentYear = new Date().getFullYear()
  const yearOptions = new Array(20).fill(null).map((a, index) => {
    const newYear = currentYear + index
    return (
      <option value={newYear} key={newYear}>
        {newYear}
      </option>
    )
  })

  return (
    <select id="cardExpirationYear" name="ccyear" aria-label="Card expiration year">
      {yearOptions}
    </select>
  )
}

const CardExpirationDate = () => (
  <>
    <label htmlFor="card-expiration" aria-label="Card expiration date">
      Credit Card Expiration Date<span style={{ fontSize: 18, color: '#E11F21' }}>*</span>
    </label>
    <div id="card-expiration" className="card-input">
      <MonthSelect />
      <YearSelect />
    </div>
  </>
)

export default CardExpirationDate
