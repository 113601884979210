import React from 'react'
import { string, node, bool, oneOfType, func } from 'prop-types'
import { Typography, Radio, Stack, Box, styled } from '@mui/material'
import { PriceAtom } from '@rtgdev/design-system'

const Wrapper = styled(Stack)(({ theme, selected }) => ({
  border: `1px solid ${selected ? theme.palette.primary.main : 'rgba(0, 83, 160, 0.12)'}`,
  borderRadius: '4px',
  flex: '1 1 auto',
  cursor: selected ? 'default' : 'pointer',
  position: 'relative',
}))

const Header = styled(Stack)(({ theme, selected }) => ({
  backgroundColor: selected ? theme.palette.primary.main : 'rgba(0, 83, 160, 0.08)',
  color: selected ? theme.palette.common.white : theme.palette.primary.dark,
  fontWeight: selected ? 600 : 500,
  paddingRight: 16,
  'h3, p': {
    color: 'inherit',
    fontSize: 18,
    fontWeight: 'inherit',
  },
}))

const LoadingOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.2)',
  color: theme.palette.common.white,
  borderRadius: '4px',
  border: `1px solid ${theme.palette.primary.main}`,
}))

const DeliveryOptionCard = ({
  children,
  deliveryFee,
  dtId,
  isChecked,
  isLoading,
  setDeliveryType,
  title,
  availableDate,
  deliveryDescription = 'self-pickup',
}) => (
  <Wrapper
    selected={isChecked}
    onClick={isChecked ? null : setDeliveryType}
    sx={{
      flexBasis: { xs: 1, md: '33%' },
      maxWidth: { xs: 'unset', md: '33%' },
      minHeight: { xs: 'unset', md: isChecked ? 'unset' : '160px' },
    }}
  >
    <Stack>
      <Header direction="row" alignItems="center" justifyContent="space-between" selected={isChecked}>
        <Stack direction="row" alignItems="center" data-testid={`${dtId}-radiobtn`}>
          <Radio
            checked={isChecked}
            sx={{ '&, &.Mui-checked': { color: isChecked ? '#fff' : '#003566' } }}
            aria-label={`delivery type ${title}, ${deliveryDescription}. Deliverable on ${availableDate}.`}
            inputProps={{
              'aria-label': `delivery type ${title}, ${deliveryDescription}. Deliverable on ${availableDate}.`,
            }}
            tabindex={0}
          />
          <Typography variant="h3">{title}</Typography>
        </Stack>
        <Typography data-testid={`${dtId}-price`}>
          {['free', '$0'].includes(deliveryFee?.toLowerCase()) ? 'Free' : <PriceAtom price={deliveryFee} />}
        </Typography>
      </Header>
      {children}
    </Stack>
    {isLoading && <LoadingOverlay />}
  </Wrapper>
)

DeliveryOptionCard.propTypes = {
  children: node,
  deliveryFee: string,
  dtId: string,
  isChecked: bool.isRequired,
  isLoading: bool.isRequired,
  setDeliveryType: func,
  title: oneOfType([string, node]).isRequired,
  availableDate: string,
  deliveryDescription: string,
}

export default DeliveryOptionCard
